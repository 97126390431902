<template>
    <div class="aa-areas-area fade-in" @click="ir(area.id)">
        <div class="area-portada" :style="'background-image: url('+ area.portada +')'"></div>
        <div class="area-titulo">
            {{area.nombre}}
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
    props: {
        datos: {
            type: Object,
            required: true
        }
    },
    setup(props){
        const router = useRouter()
        
        return {
            area: props.datos,
            ir: (id) => router.push({name:'area', params:{id}})
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-areas-area
    position: relative
    height: 410px
    .area-portada
        height: 410px
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        @include breakpoint(md)
            height: 550px
    .area-titulo
        position: absolute
        z-index: 1000
        top: 0
        writing-mode: vertical-lr
        font-size: 2rem
        font-weight: 500
        margin-left: 39%
        margin-top: 39px
        color: #fff
        text-shadow: rgb(0 0 0 / 80%) 0px 2px 4px
</style>